import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const [scrollPositionToggle, setScrollPositionToggle] = useState(false);

  const handleScroll = () => {
    const position = window.pageYOffset;
    if (position > 100) {
      setScrollPositionToggle(true);
    } else {
      setScrollPositionToggle(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return scrollPositionToggle ? (
    <Box
      sx={{
        position: 'fixed',
        bottom: '92px',
        right: '20px',
        height: '60px',
        width: '60px',
        borderRadius: '50%',
        backgroundColor: '#54b4d3',
        boxShadow: '0 0 10px rgba(0,0,0,.2)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        zIndex: 1000,
        ':hover': {
          backgroundColor: '#50abc8',
        },
      }}
      onClick={() => window.scrollTo(0, 0)}
    >
      <Typography color={'#fff'} fontSize={'24px'}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="18"
          width="14"
          viewBox="0 0 384 512"
          style={{ fill: '#FFF' }}
        >
          <path d="M214.6 41.4c-12.5-12.5-32.8-12.5-45.3 0l-160 160c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 141.2V448c0 17.7 14.3 32 32 32s32-14.3 32-32V141.2L329.4 246.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3l-160-160z" />
        </svg>
      </Typography>
    </Box>
  ) : null;
}
